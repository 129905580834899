<template>
  <q-breadcrumbs align="left" class="tw-ml-5" v-show="route.name != MAIN_ROUTE">
    <template v-for="(menu, index) in ruta">
      <q-breadcrumbs-el :icon="menu.icon??''"
                        @click="routeGo(menu.route,index != ruta.length-1 )"
                        :label="$t(menu.title)"
                        class="tw-cursor-pointer"
                        />


    </template>
  </q-breadcrumbs>
</template>

<script setup lang="ts">

import type {BreadCrumbItem} from "../models/BreadCrumbItem";
import {useI18n} from "vue-i18n";
import {MAIN_ROUTE} from '../common/crumbs_menu'

const i18n = useI18n()

const route = useRoute();
const router = useRouter();

const props = defineProps<{
  crumb: BreadCrumbItem
}>()
const ruta = ref(rutaDesdePadreAHijo(props.crumb, route.name!.toString()));

const routeGo = (routeName: string, condiction: boolean) => {
  if (condiction) {
    router.push(`/${routeName.toLowerCase()}`)
    .catch((err) => {
      console.error('Error al navegar:', err);
    });
  }
  else{
    return '';
  }
}

function rutaDesdePadreAHijo(arbol: BreadCrumbItem, nodoActual: string): BreadCrumbItem[] {
  if (nodoActual === arbol.route) {
    return [arbol];
  }

  for (const hijo of arbol.children ?? []) {
    const resultadoRecursivo = rutaDesdePadreAHijo(hijo, nodoActual);
    if (resultadoRecursivo.length > 0) {
      return [arbol, ...resultadoRecursivo];
    }
  }

  return [];
}
</script>


<style scoped>

</style>
