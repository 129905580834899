import type {MenuItem} from "../models/MenuItem";
import type {BreadCrumbItem} from "../models/BreadCrumbItem";
import orderStatuses from "~/lang/en/orderStatuses";

export const MAIN_ROUTE = 'Store'

export const breadCrumb_menu: BreadCrumbItem =
    {
        title: 'home.store',
        route: MAIN_ROUTE,
        icon: 'home',
        color: 'gray-9',
        children: [
            {
                title: 'products.title',
                route: 'Products',
                icon: 'fa-solid fa-tag',
                color: 'gray-9',
                children: [
                    {
                        title: 'products.create',
                        route: 'Products-Create',
                        icon: 'add',
                        color: 'gray-9',
                    },
                    {
                        title: 'products.edit',
                        route: 'Products-slug-edit',
                        icon: 'edit',
                        color: 'gray-9',
                    }

                ]
            },
            {
                title: 'products.show_product',
                route: 'Products-product',
                icon: 'edit',
                color: 'gray-9',
            },
            {
                title: 'users.title',
                route: 'Users',
                icon: 'group',
                color: 'gary-9',
                children: [
                    {
                        title: 'users.create',
                        route: 'Users-Create',
                        icon: 'add'
                    },
                    {
                        title: 'users.edit',
                        route: 'Users-user-edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'roles.title',
                route: 'Roles',
                icon: 'verified_user',
                children: [
                    {
                        title: 'roles.create',
                        route: 'Roles-Create',
                        icon: 'add'
                    },
                    {
                        title: 'roles.edit',
                        route: 'Roles-role-edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'point_of_sales.title',
                route: 'PointOfSales',
                icon: 'fa-solid fa-warehouse',
                children: [
                    {
                        title: 'point_of_sales.create',
                        route: 'PointOfSales-Create',
                        icon: 'add'
                    },
                    {
                        title: 'point_of_sales.edit',
                        route: 'PointOfSales-id-edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'services.title',
                route: 'services.index',
                icon: 'handyman',
                children: [
                    {
                        title: 'services.create',
                        route: 'services.create',
                        icon: 'add'
                    },
                    {
                        title: 'services.edit',
                        route: 'services.edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'services.title',
                route: 'store.services',
                icon: 'handyman'
            }, {
                title: 'home.contact_us',
                route: 'contact',
                icon: 'phone',
            },
            {
                title: 'offers.title',
                route: 'Offers',
                icon: 'shop',
                children: [
                    {
                        title: 'offers.create',
                        route: 'Offers-Create',
                        icon: 'add'
                    },
                    {
                        title: 'offers.edit',
                        route: 'Offers-discount-edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'attributes.title',
                route: 'attributes.index',
                icon: 'extension',
                children: [
                    {
                        title: 'attributes.create',
                        route: 'attributes.create',
                        icon: 'add'
                    },
                    {
                        title: 'attributes.edit',
                        route: 'attributes.edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'shoppingCard.sale_in_store',
                route: 'sales_buy_card',
                icon: 'storefront',

            },
            {
                title: 'shoppingCard.my',
                route: 'shoppingCard.my',
                icon: 'credit_card',
                children: [
                    {
                        title: 'shoppingCard.recharged_card',
                        route: 'shoppingCard.recharge',
                        icon: 'request_page'
                    },
                    {
                        title: 'shoppingCard.title',
                        route: 'shoppingCard.view',
                        icon: 'card_membership'
                    }
                ]

            },
            {
                title: 'profile.my_account',
                route: 'Profile-Edit',
                icon:'person'

            },
            {
                title: 'categories.title',
                route: 'Categories',
                icon: 'fa-solid fa-layer-group',
                children: [
                    {
                        title: 'categories.create',
                        route: 'Categories-Create',
                        icon: 'add'
                    },
                    {
                        title: 'categories.edit',
                        route: 'Categories-category-edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'orders.title',
                route: 'orders.index',
                icon: 'request_quote',
                children: [
                    {
                        title: 'orders.create',
                        route: 'orders.create',
                        icon: 'add'
                    },
                    {
                        title: 'orders.edit',
                        route: 'orders.edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'orders.title',
                route: 'Orders',
                icon: 'fa-solid fa-cart-shopping',
                children: [
                    {
                        title: 'orders.details',
                        route: 'Orders-order-show',
                        icon: 'fa-regular fa-eye'
                    }
                ]
            },
            {
                title: 'reservations.title',
                route: 'Reservations',
                icon: 'book_online',
                children: [
                    {
                        title: 'reservations.details',
                        route: 'Reservations-reservation-show',
                        icon: 'fa-regular fa-eye'
                    }
                ]
            },
            {
                title: 'reservations.title',
                route: 'Reservations-ownerReservation',
                icon: 'book_online',
                children: [
                    {
                        title: 'reservations.details',
                        route: 'Reservations-reservation-show',
                        icon: 'fa-regular fa-eye'
                    }
                ]
            },
            {
                title: 'operation.title',
                route: 'Operation',
                icon: 'fa-solid fa-scale-balanced',
                children: [
                    {
                        title: 'operation.title',
                        route: 'Operation-operation-show',
                        icon: 'fa-regular fa-eye'
                    }
                ]
            },
            {
                title: 'invoices.title',
                route: 'Invoices',
                icon: 'receipt',
                children: [
                    {
                        title: 'invoices.create',
                        route: 'Invoices-Create',
                        icon: 'add'
                    }
                ]
            },
            {
                title: 'reservations.title',
                route: 'reservations.index',
                icon: 'book_online',
                children: [
                    {
                        title: 'reservations.create',
                        route: 'reservations.create',
                        icon: 'add'
                    },
                    {
                        title: 'reservations.edit',
                        route: 'reservations.edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'companies.title',
                route: 'Companies',
                icon: 'fa-solid fa-building',
                children: [
                    {
                        title: 'companies.create',
                        route: 'Companies-Create',
                        icon: 'add'
                    },
                    {
                        title: 'companies.edit',
                        route: 'Companies-company-show',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'reservations.title',
                route: 'reservations.my',
                icon: 'book_online'
            },
            {
                title: 'orderStatuses.title',
                route: 'OrderStatuses',
                icon: 'update',
                children: [
                    {
                        title: 'orderStatuses.create',
                        route: 'OrderStatuses-Create',
                        icon: 'add'
                    },
                    {
                        title: 'orderStatuses.edit',
                        route: 'OrderStatuses-status-edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'analytics.title',
                route: 'analytics',
                icon: 'area_chart'
            },
            {
                title: 'banners.title',
                route: 'Banners',
                icon: 'image',
                children: [ 
                    {
                        title: 'banners.create',
                        route: 'Banners-create',
                        icon: 'add'
                    },
                    {
                        title: 'banners.edit',
                        route: 'Banners-id-edit',
                        icon: 'edit'
                    }
                ]
            },
            {
                title: 'report.sale_title',
                route: 'Reports-sale',
                icon: 'fa-solid fa-coins'
            },
            {
                title: 'report.inventory_title',
                route: 'Reports-inventory',
                icon: 'fa-solid fa-sheet-plastic'
            },
            {
                title: 'report.movements_title',
                route: 'Reports-movements',
                icon: 'fa-solid fa-dolly'
            },
            {
                title: 'report.sold_products_title',
                route: 'Reports-soldProduct',
                icon: 'fa-solid fa-chart-line'
            },
            {
                title: 'settings.generals',
                route: 'Settings-Generals',
                icon: 'settings_suggest'
            },
            {
                title: 'settings.customization',
                route: 'Settings-Customization',
                icon: 'palette'
            },
            {
                title: 'settings.coins',
                route: 'Coins',
                icon: 'monetization_on',
                children: [
                    {
                        title: 'coins.edit',
                        route: 'Coins-id-edit',
                        icon: 'edit',
                    }
                ]
            },
            {
                title: 'home.contact_us',
                route: 'Contact-Us',
                icon: 'group'
            },
            {
                title: 'home.cart',
                route: 'Cart',
                icon: 'fa-solid fa-cart-shopping'
            },
            {
                title: 'products.show_product',
                route: 'Products-product',
                icon: 'fa-solid fa-tag'
            },
            
        ]
    }

