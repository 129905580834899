export const useContactsStore = defineStore('contacts', {
    state: () => ({
      contacts: {
        address: '', 
        coordinates: '' , 
        email: '', 
        facebook: '', 
        favicon_path: '',
        instagram: '', 
        whatsapp: '', 
        lat:'',
        logo_path: '', 
        long:'', 
        phone: '', 
        phones: []
      },
    }),
    getters: {
        getContacts(state) {
            return state.contacts
        }
    },
    actions: {
        setContacts(newContact: any) {
            this.contacts = newContact;
        },
      },
  })